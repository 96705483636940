import React, { useState } from 'react';

// Layout
import Layout from '../components/layout';
import PricingBlock from '../components/pricing_block';
import LogosBlock from '../components/logos_block';
import FeaturesBlock from "../components/FeaturesBlock";

// Databases
import MysqlIcon from '../images/databases/mysql.png';
import MongodbIcon from '../images/databases/mongodb.png';
import PostgresqlIcon from '../images/databases/postgresql-flat.png';
import SqliteIcon from '../images/databases/sqlite-flat.png';

// Data
import config from '../data/general';
import FAQBlock from '../components/FAQ';
// import { FiArrowRight, FiCheckSquare } from 'react-icons/fi';
// import ImageSwitch from '../components/ImageSwitch';
import PrivacyScreen from '../images/screens/privacy-screen.png';
import BaselineDbs from '../images/baseline-dbs.svg';
import MainScreenSimple from '../images/screens/main-screen-simple.png';
import DashboardScreenSimple from '../images/screens/dashboard-screen-simple.png';
import CollaborationScreenSimple from '../images/screens/collaboration-screen-simple.png';
import MainScreenBig from '../images/screens/main-screen-big.png';

const IndexPage = () => {
  const [billingInterval, setBillingInterval] = useState('year');
  const [benefit, setBenefit] = useState('developers');

  const tabClass = 'text-md md:text-lg font-medium cursor-pointer px-4 py-2 md:px-4 md:py-2 rounded-lg';
  const tabActiveClass = `${tabClass} bg-amblue text-white`;

  return (
    <Layout>
      <>
        <section class="py-6 sm:py-16 lg:py-36 main-hero relative">
          <div className='gradient-shadow'></div>
          <div class="px-7 mx-auto max-w-7xl">
            <div class="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
              <div>
                <h1 class="text-3xl font-bold text-black sm:text-4xl xl:text-4xl xl:leading-tight">Turn your <img src={BaselineDbs} alt="Databases" className="inline mx-1 max-h-8 lg:max-h-10" /> databases into actionable data within minutes</h1>
                <p class="text-xl pt-4 pb-8 text-gray-600 font-normal lg:leading-9">Add a dashboard on top of your application in 10min. Don't build it from scratch. Try for Free.</p>

                <form action="https://app.adminmate.io/signup" method="GET" class="relative rounded-full">
                  <div class="relative">
                    <div class="absolute rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500"></div>
                    <div class="relative">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-8">adminmate.io/</div>
                      <input type="text" name="handle" placeholder="your-project" class="block w-full py-4 pr-6 text-black placeholder-gray-500 bg-gray-100 border border-transparent rounded-full pl-36 ml-1 sm:py-5 focus:border-transparent focus:ring-0" />
                    </div>
                  </div>

                  <div class="sm:absolute flex sm:right-1.5 sm:inset-y-1.5 mt-4 sm:mt-0">
                    <button type="submit" class="inline-flex items-center justify-center w-full px-7 py-5 text-sm font-semibold tracking-widest text-black uppercase transition-all duration-200 bg-amblue rounded-full sm:w-auto sm:py-3 hover:opacity-90 text-white">Build my back-office</button>
                    <div className='absolute mt-16 w-full text-center text-xs font-semibold pt-2 text-gray-700 hidden sm:block'>NO CC REQUIRED</div>
                  </div>
                </form>
              </div>

              <div>
                {/* <img class="w-full" src={MainScreenSimple} alt="" /> */}
                {/* <ImageSwitch /> */}
              </div>
            </div>
          </div>
          <div className="right-screen-container">
            {/* <div className="right-screen-wrapper"> */}
              <img src={MainScreenBig} alt="" />
            {/* </div> */}
          </div>
        </section>

        <div className="container mx-auto">
          <div className="w-5/6 md:w-4/5 mx-auto py-8 md:py-16">
            <LogosBlock />
          </div>
        </div>

        <div className="bg-gray-50">
          <div className="py-10 md:py-20 w-5/6 md:w-4/5 mx-auto" id="benefits">

            <h2 className="text-3xl md:text-4xl xl:text-5xl font-bold leading-tight text-center">A win-win solution for the whole team</h2>

            <div className="pt-6 md:pt-12 mx-auto flex justify-center gap-4">
              <div className={benefit === 'developers' ? tabActiveClass : tabClass} onClick={() => setBenefit('developers')}>For developers</div>
              <div className={benefit === 'teams' ? tabActiveClass : tabClass} onClick={() => setBenefit('teams')}>For teams</div>
            </div>

            <div className="pt-4 lg:px-32 md:pt-8 mx-auto text-center">
              <h2 className="text-md md:text-xl font-medium md:font-normal leading-7 md:leading-10 text-justify md:text-center text-gray-600">
                {benefit === 'developers' ? (
                  <>Stop loosing time working on your internal tools, just focus on your product. Adminmate will make your back-office development <span className="colored">easy</span>. It works great for <span className="colored">MySQL, PostgreSQL, MongoDB</span> and a lot more to come!</>
                ) : (
                  <>No need to wait for the tech teams to <span className="colored">scale operations</span>. You can now scale at market speed. From resolving <span className="colored">customer support</span> tickets to <span className="colored">onboarding</span> new partners, Adminmate is going to become your <span className="colored">favorite partner</span>.</>
                )}
              </h2>
            </div>

          </div>
        </div>

        <div className="blue-background py-2 md:py-4" id="features">
          <div className="px-7 mx-auto max-w-7xl my-14 lg:flex gap-12 items-center">
            <div className="flex-3 mb-5 md:mb-0">
              <img src={MainScreenSimple} alt="List screen" className="rounded-lg" />
            </div>
            <div className="flex-2">
              <div className="section-title">Data explorer</div>
              <h2 className="text-2xl md:text-2xl lg:text-3xl font-bold mb-4">
                Finally find the data you are looking for
              </h2>
              <p className="text-gray-600">Access & manage all your data within your database with our powerful tools.</p>
              {/* <p className="text-gray-600">The data explorer gives you access to all the models and data in your database.<br />It allows you to Create / Read / Update / Delete your data.</p> */}
              <ul className="features-list">
                <li className="text-gray-600">Search & filters</li>
                <li className="text-gray-600">Update & delete data</li>
                <li className="text-gray-600">Create custom data segments with custom code</li>
                <li className="text-gray-600">Create business related custom actions with code</li>
              </ul>
              <a
                href={config.signup_link}
                className="mt-3 inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-full text-base font-medium text-white bg-amblue hover:opacity-90"
              >
                Get started for free
              </a>
            </div>
          </div>

          <div className="px-7 mx-auto max-w-7xl my-14 lg:flex lg:flex-row-reverse gap-12 items-center">
            <div className="flex-3 mb-5 md:mb-0">
              <img src={DashboardScreenSimple} alt="List screen" className="rounded-lg" />
            </div>
            <div className="flex-2">
              <div className="section-title dashboard-title">Dashboard</div>
              <h2 className="text-2xl md:text-2xl lg:text-3xl font-bold mb-4">
                Visualize your data like never before
              </h2>
              <p className="text-gray-600">Take better business decisions by displaying your business key metrics.</p>
              <ul className="features-list">
                <li className="text-gray-600">Create unlimited dashboards & charts</li>
                <li className="text-gray-600">Build pre-configured charts only with UI</li>
                <li className="text-gray-600">Build custom charts with custom code</li>
                <li className="text-gray-600">Manage access control for your team & partners</li>
              </ul>
              <a
                href={config.signup_link}
                className="mt-3 inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-full text-base font-medium text-white bg-amblue hover:opacity-90"
              >
                Get started for free
              </a>
            </div>
          </div>

          <div className="px-7 mx-auto max-w-7xl my-14 lg:flex gap-12 items-center">
            <div className="flex-3 mb-5 md:mb-0">
              <img src={CollaborationScreenSimple} alt="List screen" className="rounded-lg" />
            </div>
            <div className="flex-2">
              <div className="section-title collaboration-title">Collaboration</div>
              <h2 className="text-2xl md:text-2xl lg:text-3xl font-bold mb-4">
                Work efficiently with your team
              </h2>
              <p className="text-gray-600">Work together a better way. Follow & track everything that happened on your data from your back-office.</p>
              <ul className="features-list">
                <li className="text-gray-600">Track and visualize your team work</li>
                <li className="text-gray-600">React & comment on activities</li>
                <li className="text-gray-600">Ask questions and tag your teammates</li>
                <li className="text-gray-600">Filter by type of activities</li>
              </ul>
              <a
                href={config.signup_link}
                className="mt-3 inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-full text-base font-medium text-white bg-amblue hover:opacity-90"
              >
                Get started for free
              </a>
            </div>
          </div>

          <div className="px-7 mx-auto max-w-7xl my-14 lg:flex lg:flex-row-reverse gap-12 items-center">
            <div className="flex-3 mb-5 md:mb-0">
              <img src={PrivacyScreen} alt="List screen" className="rounded-lg mx-auto" />
            </div>
            <div className="flex-2">
              <div className="section-title privacy-title">Privacy first</div>
              <h2 className="text-2xl md:text-2xl lg:text-3xl font-bold mb-4">
                Only you can see your data
              </h2>
              <p className="text-gray-600 mb-3">Privacy sets us apart from similar tools.</p>
              <p className="text-gray-600 mb-3">
                To access your data, you need your own password (stored on Adminmate server) as well as the master password (stored on your server).
              </p>
              <p className="text-gray-600">
                Even if we wanted or if our data got leaked. we could not access any of your data as the 2 passwords are required.
              </p>
              <a
                href={config.signup_link}
                className="mt-5 inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-full text-base font-medium text-white bg-amblue hover:opacity-90"
              >
                Get started for free
              </a>
            </div>
          </div>
        </div>

        <div className="px-7 mx-auto max-w-7xl my-20">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight text-center mb-4">A comprehensive solution for dev & team</h2>
          <p className="text-lg pt-4 pb-6 text-gray-600 font-normal lg:leading-8 lg:w-3/6 mx-auto text-center mb-8">We’ve spent 2 years working on everything that a admin panel requires. Adminmate is a neverending story on which we will iterate.</p>
          <FeaturesBlock />
        </div>

        <div className="px-7 mx-auto max-w-7xl my-20" id="pricing">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight text-center mb-14">Pay as you grow</h2>
          <PricingBlock interval={billingInterval} setBillingInterval={setBillingInterval} />
        </div>

        <div className="bg-gray-50">
          <FAQBlock />
        </div>

        <div className="px-7 mx-auto max-w-7xl py-20">
          <h2 className="text-3xl font-bold leading-tight text-center">Compatible with all major databases</h2>
          <div className="flex justify-center mt-8 md:mt-14">
            <div className="text-center flex-shrink-0">
              <img src={MysqlIcon} alt="Mysql" className="h-12 md:h-20 mx-auto" />
              <div className="mt-1 md:mt-3 text-sm text-gray-500">mysql</div>
            </div>
            <div className="w-6 md:w-12"></div>
            <div className="text-center flex-shrink-0">
              <img src={PostgresqlIcon} alt="Postgresql" className="h-12 md:h-20 mx-auto" />
              <div className="mt-1 md:mt-3 text-sm text-gray-500">postgresql</div>
            </div>
            <div className="w-6 md:w-12"></div>
            <div className="text-center flex-shrink-0">
              <img src={SqliteIcon} alt="sqlite" className="h-12 md:h-20 mx-auto" />
              <div className="mt-1 md:mt-3 text-sm text-gray-500">sqlite</div>
            </div>
            <div className="w-6 md:w-12"></div>
            <div className="text-center flex-shrink-0">
              <img src={MongodbIcon} alt="sqlite" className="h-12 md:h-20 mx-auto" />
              <div className="mt-1 md:mt-3 text-sm text-gray-500">mongodb</div>
            </div>
          </div>
        </div>

        <div className="px-7 mx-auto max-w-7xl">
          <div className="bg-gray-50 rounded-xl mt-6 mb-12 md:mb-20">
            <div className="container px-6 py-8 mx-auto sm:px-6 lg:py-16 lg:px-12 lg:flex lg:items-center lg:justify-between">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900 leading-7 sm:text-4xl sm:leading-10">
                <div>Ready to empower your data?</div>
                <div className="text-amblue mt-1">Connect your database for free.</div>
              </h3>
              <div className="flex mt-8 lg:flex-shrink-0 lg:mt-0">
                <div className="inline-flex rounded-md">
                  <a href={config.signup_link} className="px-6 py-3 border border-transparent rounded-full text-base font-medium text-white transition duration-500 ease-in-out bg-amblue hover:opacity-90">Get started for free</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
};

export default IndexPage;