import * as React from 'react';

// Logos
import aircall from '../images/customers/aircall.svg';
import amazingdev from '../images/customers/amazingdev.svg';
import vraistudio from '../images/customers/vraistudio.png';
import awesomelab from '../images/customers/awesomelab.png';
import cowlendar from '../images/customers/cowlendar.png';
import penida from '../images/customers/penida.png';

const CTABlock = ({ className }) => {
  return (
    <div className={`hero__logos text-center fade-in fade-in-third w-5/6 mx-auto ${className ? className : ''}`}>
      <span className="font-medium text-gray-500">Join 50+ businesses who choose Adminmate</span>
      <ul className="list-none flex justify-center items-center content-center mt-3">
        <li className="p-4 px-2 md:px-4"><img src={aircall} className="w-18" alt="Aircall" /></li>
        <li className="p-4 px-2 md:px-4"><img src={vraistudio} className="w-48" alt="Vrai Studio" /></li>
        <li className="p-4 px-2 md:px-4"><img src={amazingdev} className="w-22" alt="Amazing.dev" /></li>
        <li className="hidden lg:block px-4">
          <a href="https://apps.shopify.com/cowlendar" target='_blank'>
            <img src={cowlendar} className="w-44" alt="Cowlendar" />
          </a>
        </li>
        <li className="hidden lg:block px-4">
          <a href="https://penida.io" target='_blank'>
            <img src={penida} className="w-32" alt="Penida" />
          </a>
        </li>
        <li className="hidden lg:block px-4"><img src={awesomelab} className="w-52" alt="Awesome Lab" /></li>
        {/* <li className="hidden md:block px-4"><img src={amazingdev} className="w-22" alt="Amazing.dev" /></li> */}
      </ul>
    </div>
  );
};

export default CTABlock;